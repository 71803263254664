import { useRef, useState, useEffect, useContext, memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../Api/BaseApiController/axios";
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  InputGroup
} from 'react-bootstrap';
import AlertHandler from "../Api/BaseApiController/AlertHandler";
import HandleError from "../Api/BaseApiController/ErrorHandling";
import { BrandingContext } from "../Context/BrandingContext";

const LOGIN_URL = "/api/Security/CreateToken";
const WHO_AM_I = "api/Security/Me"

const Login = () => {
  const { brandingDetails, updateBrandingColor } = useContext(BrandingContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, [userName, password]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const responeToken = await axios.post(
        LOGIN_URL,
        JSON.stringify({ userName, password })
      );

      const accessToken = responeToken?.data?.accessToken;
      const refreshToken = responeToken?.data?.refreshToken;

      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));

      const responseUser = await axios.get(
        WHO_AM_I,
        {
          headers: {
            'Authorization': `Bearer ${responeToken?.data?.accessToken}`
          }
        }
      );

      const authUser = responseUser?.data?.UserName
      localStorage.setItem("authUser", JSON.stringify(authUser));
      const myId = responseUser?.data?.Id
      const homePath = responseUser?.data?.HomePath ? responseUser.data.HomePath : "/Home"
      localStorage.setItem("myId", JSON.stringify(myId));
      setUserName("");
      setPassword("");
      AlertHandler("Success", "Ingresaste con exito");
      setTimeout(function () {
        window.location.href = homePath
      }, 1000);
    } catch (err) {
      HandleError(err);
    }
  };

  return (
    <Container className="my-5">
      <Card>
        <Row className='g-0'>
          <Col md='5'>
            <Card.Img src={brandingDetails.LoginLogoUrl} alt="login form" className='rounded-start w-100' />
          </Col>
          <Col md='5'>
            <Card.Body className='d-flex flex-column'>
              <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: '1px' }}>Ingresa a tu cuenta</h5>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-4">
                  <Form.Label>Usuario</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese Usuario"
                    size="lg"
                    ref={userRef}
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Contraseña</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Contraseña"
                      size="lg" onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    <Button variant="outline-secondary" onClick={toggleShowPassword}>
                      {showPassword ? <EyeSlash className="mx-2" /> : <Eye className="mx-2" />}
                    </Button>
                  </InputGroup>
                </Form.Group>
                <Button variant="dark" size="lg" className="mb-4 px-5" type="submit">
                  Ingresar
                </Button>
                <div>
                  <a className="small text-muted" href="#!">¿Olvistaste tu contraseña?</a>
                </div>
              </Form>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default memo(Login);
