import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import { useCustomTheme } from './DashboardStyle';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 256;

export default function Dashboard() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useCustomTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const [homePage, setHomePage] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const startApplication = sessionStorage.getItem("startApplication");

    if (homePage && !startApplication) {
      navigate(homePage.path);
      sessionStorage.setItem("startApplication", "true");
    }


  }, [homePage]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {isSmUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              firstCategory={setHomePage}
            />
          )}

          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: 'block', xs: 'none' } }}
            firstCategory={setHomePage}
          />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header onDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ flex: 1, py: 6, px: 4 }}>
            <Content />
          </Box>
          <Box component="footer" sx={{ p: 2 }}>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}