import GetPatient from "../Patients/GetPatient"
import GetCountry from "../Countries/GetCountry"
import GetProfile from "../Profiles/GetProfile"
import GetDocumentType from "../DocumentTypes/GetDocumentType"
import GetRelationship from "../Relationships/GetRelationship"
import GetSpecialty from "../Specialties/GetSpecialty"
import GetRole from "../Roles/GetRole"
import GetUser from "../Users/GetUser"
import GetAssistance from "../Assistances/GetAssistance"
import GetCareModality from "../CareModalities/GetCareModality"
import GetService from "../Services/GetService"
import GetProfessional from "../Professional/GetProfessional"
import GetProvince from "../Provinces/GetProvince"
import GetTown from "../Towns/GetTown"
import GetProfessionalSchedule from "../Professional/GetProfessionalSchedule"
import GetAccount from "../Accounts/GetAccount"
import GetZoomAccount from "../ConnectedZoomAccounts/GetZoomAccount"
import GetPrescriptions from "../Prescriptions/GetPrescription"
import PostPrescriptions from "../Prescriptions/PostPrescription"
import PutPrescriptions from "../Prescriptions/PutPrescription"
import DeletePrescriptions from "../Prescriptions/DeletePrescription"
import PostProfessionalSchedule from "../Professional/PostProfessionalSchedule"
import PutProfessionalSchedule from "../Professional/PutProfessionalSchedule"
import DeleteProfessionalSchedule from "../Professional/DeleteProfessionalSchedule"
import PostProvince from "../Provinces/PostProvince"
import PostTown from "../Towns/PostTown"
import PostProfessional from "../Professional/PostProfessional"
import GetRelatedPeople from "../RelatedPeoples/GetRelatedPeople"
import PostCountry from "../Countries/PostCountry";
import PostUser from "../Users/PostUser"
import PostAssistance from "../Assistances/PostAssistance"
import PostCareModality from "../CareModalities/PostCareModality"
import PostService from "../Services/PostService"
import PostRelationship from "../Relationships/PostRelationship"
import PostDocumentType from "../DocumentTypes/PostDocumentType"
import PostProfile from "../Profiles/PostProfile"
import PostPatient from "../Patients/PostPatient";
import PostSpecialty from "../Specialties/PostSpecialty"
import PostRole from "../Roles/PostRole";
import PostResetPassword from "../ChangePassword/PostResetPassword"
import PutProvince from "../Provinces/PutProvince"
import PutTown from "../Towns/PutTown"
import PostRelatedPeople from "../RelatedPeoples/PostRelatedPeople"
import PutProfessional from "../Professional/PutProfessional"
import PutRelationship from "../Relationships/PutRelationship"
import PutAssistance from "../Assistances/PutAssistance"
import PutCareModality from "../CareModalities/PutCareModality"
import PutService from "../Services/PutService"
import PutCountry from "../Countries/PutCountry";
import PutProfile from "../Profiles/PutProfile"
import PutUser from "../Users/PutUser"
import PutSpecialty from "../Specialties/PutSpecialty"
import PutRelatedPeople from "../RelatedPeoples/PutRelatedPeople"
import PutDocumentType from "../DocumentTypes/PutDocumentType"
import PutPatient from "../Patients/PutPatient";
import PutRole from "../Roles/PutRole";
import DeleteProvince from "../Provinces/DeleteProvince"
import DeleteTown from "../Towns/DeleteTown"
import DeleteProfessional from "../Professional/DeleteProfessional"
import DeleteAssistance from "../Assistances/DeleteAssistance"
import DeleteCareModality from "../CareModalities/DeleteCareModality"
import DeleteService from "../Services/DeleteService"
import DeleteSpecialty from "../Specialties/DeleteSpecialty"
import DeleteProfile from "../Profiles/DeleteProfile"
import DeleteCountry from "../Countries/DeleteCountry";
import DeleteRelatedPeople from "../RelatedPeoples/DeleteRelatedPeople"
import DeleteRelationship from "../Relationships/DeleteRelationship"
import DeleteDocumentType from "../DocumentTypes/DeleteDocumentType";
import DeletePatient from "../Patients/DeletePatient";
import DeleteUser from "../Users/DeleteUser"
import DeleteRole from "../Roles/DeleteRole";
import DeleteZoomAccount from "../ConnectedZoomAccounts/DeleteZoomAccount"
import GetEntities from "../../../GenericComponents/Entities"
import GetAppointment from "../Appointments/GetAppointment"
import DeleteAppointment from "../Appointments/DeleteAppointment"
import PostAppointment from "../Appointments/PostAppointment"
import PutAppointment from "../Appointments/PutAppointment"
import GetAppointmentWithSchedule from "../Appointments/GetProfessionalScheduleAppointment"
import GetProfessionalAppointment from "../Appointments/PostProfessionalAppointment"
import PatchProfessional from "../Professional/PatchProfessional"
import PatchZoomAccount from "../ConnectedZoomAccounts/PatchZoomAccount"
import PutAppointmentHC from "../Appointments/PutAppointmentHC"
import PutAccount from "../Accounts/PutAccount"
import GetSite from "../Sites/GetSite"
import DeleteSite from "../Sites/DeleteSite"
import PutSite from "../Sites/PutSite"
import PostSite from "../Sites/PostSite"
import GetMyCalendar from "../MyCalendar/GetMyCalendar"
import PostSelfserviceAppointment from "../GetSelfserviceAppointment/PostSelfserviceAppointment"
import GetGeneralPractitionerSchedule from "../GetSelfserviceAppointment/GetGeneralPractitionerSchedule"
import GetMyAppointmentsFuture from "../MyAppointments/GetMyAppointmentsFuture"
import GetMyAppointmentsPast from "../MyAppointments/GetMyAppointmentsPast"
import PostChangePassword from "../ChangePassword/PostChangePassword"
import GetHomeKPIs from "../HomeKPI/GetHomeKPIs"
import GetRoom from "../Rooms/GetRoom"
import DeleteRoom from "../Rooms/DeleteRoom"
import PutRoom from "../Rooms/PutRoom"
import PostRoom from "../Rooms/PostRoom"
import GetHealthInsurance from "../HealthInsurance/GetHealthInsurance"
import DeleteHealthInsurance from "../HealthInsurance/DeleteHealthInsurance"
import PutHealthInsurances from "../HealthInsurance/PutHealthInsurance"
import PostHealthInsurances from "../HealthInsurance/PostHealthInsurance"
import GetHealthInsuranceCopayments from "../HealthInsuranceCopayment/GetHealthInsuranceCopayment"
import DeleteHealthInsuranceCopayment from "../HealthInsuranceCopayment/DeleteHealthInsuranceCopayment"
import PutHealthInsuranceCopayment from "../HealthInsuranceCopayment/PutHealthInsuranceCopayment"
import PostHealthInsuranceCopayment from "../HealthInsuranceCopayment/PostHealthInsuranceCopayment"
import GetAllAppointments from "../Appointments/GetAllAppointments"
import PostZoomAccount from "../ConnectedZoomAccounts/PostZoomAccount"
import GetResendAppointmentNotification from "../Appointments/GetResendAppointmentNotification"
import GetMedicine from "../Medicine/GetMedicine"
import GetGender from "../Gender/GetGender"
import PostGender from "../Gender/PostGender"
import PutGender from "../Gender/PutGender"
import DeleteGender from "../Gender/DeleteGender"
import GetElectronicPrescriptionProvider from "../ElectronicPrescription/GetElectronicPrescriptionProviders"
import GetElectronicPrescriptionHealthInsurances from "../ElectronicPrescription/GetElectronicPrescriptionHealthInsurances"
import GetAccountDetails from "../Accounts/GetAccountDetails"

const Entities = GetEntities()

const GetMap = new Map()
GetMap.set(Entities.Asistencia, GetAssistance);
GetMap.set(Entities.Especialidad, GetSpecialty);
GetMap.set(Entities.Familiares, GetRelatedPeople);
GetMap.set(Entities.Localidad, GetTown);
GetMap.set(Entities.ModalidadAtencion, GetCareModality);
GetMap.set(Entities.Paciente, GetPatient);
GetMap.set(Entities.Pais, GetCountry);
GetMap.set(Entities.Parentesco, GetRelationship);
GetMap.set(Entities.Perfil, GetProfile);
GetMap.set(Entities.Profesional, GetProfessional);
GetMap.set(Entities.Provincia, GetProvince);
GetMap.set(Entities.Rol, GetRole);
GetMap.set(Entities.Prestacion, GetService);
GetMap.set(Entities.TipoDocumento, GetDocumentType);
GetMap.set(Entities.Usuario, GetUser)
GetMap.set(Entities.Agenda, GetProfessionalSchedule)
GetMap.set(Entities.Turno, GetAppointment)
GetMap.set(Entities.AgendaProfesionalConTurno, GetAppointmentWithSchedule)
GetMap.set(Entities.Sede, GetSite)
GetMap.set(Entities.MiAgenda, GetMyCalendar)
GetMap.set(Entities.MisTurnosFuturos, GetMyAppointmentsFuture)
GetMap.set(Entities.MisTurnosPasados, GetMyAppointmentsPast)
GetMap.set(Entities.HorarioGeneralProfesional, GetGeneralPractitionerSchedule)
GetMap.set(Entities.HomeKPI, GetHomeKPIs)
GetMap.set(Entities.ObraSocial, GetHealthInsurance)
GetMap.set(Entities.Consultorio, GetRoom)
GetMap.set(Entities.Copago, GetHealthInsuranceCopayments)
GetMap.set(Entities.Turnos, GetAllAppointments)
GetMap.set(Entities.Cuenta, GetAccount)
GetMap.set(Entities.CuentasZoom, GetZoomAccount)
GetMap.set(Entities.ResendAppointmentNotification, GetResendAppointmentNotification)
GetMap.set(Entities.Recetas, GetPrescriptions)
GetMap.set(Entities.Medicina, GetMedicine)
GetMap.set(Entities.Genero, GetGender)
GetMap.set(Entities.ProvedorElectronico, GetElectronicPrescriptionProvider)
GetMap.set(Entities.ObraSocialFarmaLink, GetElectronicPrescriptionHealthInsurances)
GetMap.set(Entities.CuentaPublic, GetAccountDetails)

const DeleteMap = new Map()
DeleteMap.set(Entities.Asistencia, DeleteAssistance);
DeleteMap.set(Entities.Especialidad, DeleteSpecialty);
DeleteMap.set(Entities.Familiares, DeleteRelatedPeople);
DeleteMap.set(Entities.Localidad, DeleteTown);
DeleteMap.set(Entities.ModalidadAtencion, DeleteCareModality);
DeleteMap.set(Entities.Paciente, DeletePatient);
DeleteMap.set(Entities.Pais, DeleteCountry);
DeleteMap.set(Entities.Parentesco, DeleteRelationship);
DeleteMap.set(Entities.Perfil, DeleteProfile);
DeleteMap.set(Entities.Profesional, DeleteProfessional);
DeleteMap.set(Entities.Provincia, DeleteProvince);
DeleteMap.set(Entities.Rol, DeleteRole);
DeleteMap.set(Entities.Prestacion, DeleteService);
DeleteMap.set(Entities.TipoDocumento, DeleteDocumentType);
DeleteMap.set(Entities.Usuario, DeleteUser)
DeleteMap.set(Entities.Agenda, DeleteProfessionalSchedule)
DeleteMap.set(Entities.Turno, DeleteAppointment)
DeleteMap.set(Entities.ObraSocial, DeleteHealthInsurance)
DeleteMap.set(Entities.Sede, DeleteSite)
DeleteMap.set(Entities.Consultorio, DeleteRoom)
DeleteMap.set(Entities.Copago, DeleteHealthInsuranceCopayment)
DeleteMap.set(Entities.CuentasZoom, DeleteZoomAccount)
DeleteMap.set(Entities.Recetas, DeletePrescriptions)
DeleteMap.set(Entities.Genero, DeleteGender)

const PostMap = new Map()
PostMap.set(Entities.Asistencia, PostAssistance);
PostMap.set(Entities.Especialidad, PostSpecialty);
PostMap.set(Entities.Familiares, PostRelatedPeople);
PostMap.set(Entities.Localidad, PostTown);
PostMap.set(Entities.ModalidadAtencion, PostCareModality);
PostMap.set(Entities.Paciente, PostPatient);
PostMap.set(Entities.Pais, PostCountry);
PostMap.set(Entities.Parentesco, PostRelationship);
PostMap.set(Entities.Perfil, PostProfile);
PostMap.set(Entities.Profesional, PostProfessional);
PostMap.set(Entities.Provincia, PostProvince);
PostMap.set(Entities.Rol, PostRole);
PostMap.set(Entities.Prestacion, PostService);
PostMap.set(Entities.TipoDocumento, PostDocumentType);
PostMap.set(Entities.Usuario, PostUser)
PostMap.set(Entities.Agenda, PostProfessionalSchedule)
PostMap.set(Entities.Turno, PostAppointment)
PostMap.set(Entities.Password, PostChangePassword)
PostMap.set(Entities.AutogestionTurno, PostSelfserviceAppointment)
PostMap.set(Entities.ObraSocial, PostHealthInsurances)
PostMap.set(Entities.Sede, PostSite)
PostMap.set(Entities.Consultorio, PostRoom)
PostMap.set(Entities.Copago, PostHealthInsuranceCopayment)
PostMap.set(Entities.AgendaProfesional, GetProfessionalAppointment)
PostMap.set(Entities.CuentasZoom, PostZoomAccount)
PostMap.set(Entities.Recetas, PostPrescriptions)
PostMap.set(Entities.ResetPassword, PostResetPassword)
PostMap.set(Entities.Genero, PostGender)

const PutMap = new Map()
PutMap.set(Entities.Asistencia, PutAssistance);
PutMap.set(Entities.Especialidad, PutSpecialty);
PutMap.set(Entities.Familiares, PutRelatedPeople);
PutMap.set(Entities.Localidad, PutTown);
PutMap.set(Entities.ModalidadAtencion, PutCareModality);
PutMap.set(Entities.Paciente, PutPatient);
PutMap.set(Entities.Pais, PutCountry);
PutMap.set(Entities.Parentesco, PutRelationship);
PutMap.set(Entities.Perfil, PutProfile);
PutMap.set(Entities.Profesional, PutProfessional);
PutMap.set(Entities.Provincia, PutProvince);
PutMap.set(Entities.Rol, PutRole);
PutMap.set(Entities.Prestacion, PutService);
PutMap.set(Entities.TipoDocumento, PutDocumentType);
PutMap.set(Entities.Usuario, PutUser)
PutMap.set(Entities.Agenda, PutProfessionalSchedule)
PutMap.set(Entities.Turno, PutAppointment)
PutMap.set(Entities.ObraSocial, PutHealthInsurances)
PutMap.set(Entities.Sede, PutSite)
PutMap.set(Entities.Consultorio, PutRoom)
PutMap.set(Entities.Copago, PutHealthInsuranceCopayment)
PutMap.set(Entities.Cuenta, PutAccount)
PutMap.set(Entities.Recetas, PutPrescriptions)
PutMap.set(Entities.Genero, PutGender)

const PatchMap = new Map()
PatchMap.set(Entities.Profesional, PatchProfessional)
PatchMap.set(Entities.CuentasZoom, PatchZoomAccount)

export function GetEndPoint(type) {
    return GetMap.get(type)
}

export function DeleteEndPoint(type) {
    return DeleteMap.get(type)
}

export function PostEndPoint(type) {
    return PostMap.get(type)
}

export function PutEndPoint(type) {
    return PutMap.get(type)
}

export function PatchEndPoint(type) {
    return PatchMap.get(type)
}