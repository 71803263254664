import React, { useState, useRef } from 'react';
import { Card, Container, Button, Row, Form } from "react-bootstrap";
import GetEntities from '../GenericComponents/Entities';
import { PostEndPoint } from "../Api/Controllers/Common/EndPoints"
import FormattedTextBox from "../GenericComponents/FormattedTextBox"
import Swal from "sweetalert2";
import { Eye, EyeSlash } from 'react-bootstrap-icons';

const Entity = GetEntities()

const confirmAction = (action) => {
    const alertConfig = {
        showConfirmButton: true,
        width: 500,
        position: "center",
        icon: "question",
        title: "¿Desea cambiar la contraseña?",
        confirmButtonColor: "green",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar"
    };

    Swal.fire(alertConfig).then((result) => {
        if (result.isConfirmed) {
            action()
        }
    })
}

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [validated, setValidated] = useState(false);
    const form = useRef(null);

    const handleOldPasswordChange = event => {
        setOldPassword(event.target.value)
    }

    const handleNewPasswordChange = event => {
        setNewPassword(event.target.value)
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const changePassword = event => {
        setValidated(form.current.checkValidity() === false);
        if (form.current.checkValidity() === false) {
            event.stopPropagation();
        } else {
            confirmAction(() => {
                const apiChangePassword = PostEndPoint(Entity.Password)
                const body = {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }
                const success = apiChangePassword(body)
                if (success) {
                    setNewPassword("")
                    setOldPassword("")
                    setShowPassword(!showPassword)
                }
            })
        }
    }

    return (
        <Container>
        <Card>
          <Card.Header>
            <h2>Cambiar contraseña</h2>
          </Card.Header>
          <Card.Body>
            <Form noValidate validated={validated} key="formProfile" ref={form}>
              <FormattedTextBox
                label="Contraseña actual"
                type={showPassword ? "text" : "password"}
                placeholder="Contraseña actual"
                name="oldPassword"
                value={oldPassword}
                disabled={false}
                required={true}
                handleChange={handleOldPasswordChange}
              >
                <Button
                  variant="link"
                  onClick={toggleShowPassword}
                  style={{
                    color: "inherit",
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                >
                  {showPassword ? (
                    <EyeSlash className="mx-2" size={20} />
                  ) : (
                    <Eye className="mx-2" size={20} />
                  )}
                </Button>
              </FormattedTextBox>
      
              <FormattedTextBox
                label="Contraseña nueva"
                type={showPassword ? "text" : "password"}
                placeholder="Nueva contraseña"
                name="newPassword"
                value={newPassword}
                disabled={false}
                required={true}
                handleChange={handleNewPasswordChange}
              >
                <Button
                  variant="link"
                  onClick={toggleShowPassword}
                  style={{
                    color: "inherit",
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                >
                  {showPassword ? (
                    <EyeSlash className="mx-2" size={20} />
                  ) : (
                    <Eye className="mx-2" size={20} />
                  )}
                </Button>
              </FormattedTextBox>
            </Form>
            <Row>
              <Button variant="primary" onClick={changePassword}>
                Confirmar
              </Button>
            </Row>
          </Card.Body>
        </Card>
      </Container>      
    );
}

export default ChangePassword