import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom'

function ErrorPage() {
    //Esto deberia ir en el login
    const currentURL = window.location.href
    console.log(new Error(currentURL +" is an invalid URL, please go to home page"))
    return(
        <>
            <Alert key="danger" variant="danger">
                URL invalida, por favor vuelva a la pagina de inicio,{' '}
                <Link to={'/Home'}>IR PAGINA DE INICIO </Link> 
            </Alert>
        </>
    );
}

export default ErrorPage