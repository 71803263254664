import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
  useContext
} from "react";
import { Row, Col, Form } from "react-bootstrap";
import FormattedDropdown from "../GenericComponents/FormattedDropdown";
import FormattedTextBox from "../GenericComponents/FormattedTextBox";
import ToolBar from "../GenericComponents/ToolBar";
import ItemSelector from "../GenericComponents/ItemSelector";
import GetEntities from "../GenericComponents/Entities";
import { PostEndPoint, PutEndPoint } from "../Api/Controllers/Common/EndPoints";
import moment from "moment";
import SelfserviceAppointment from "../../Models/Appointments/SelfserviceAppointment";
import Appointments from "../../Models/Appointments/Appointments";
import FormattedCurrencyInput from "../GenericComponents/FormattedCurrencyInput";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";
import { AccountContext } from "../Context/AccountContext";

const Entity = GetEntities();
const postScheduleApi = PostEndPoint(Entity.Turno);
const putScheduleApi = PutEndPoint(Entity.Turno);
const postSelfServiceCreateApi = PostEndPoint(Entity.AutogestionTurno);

function timeFormatter(time) {
  return time ? moment(`2000-01-01 ${time}`).format("HH:mm") : "";
}

const initialState = {
  serviceRow: "",
  site: "",
  room: "",
  currentDate: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  coPaymentCostPaid: 0,
  healthInsurance: "",
  careModality: "",
  assistance: "",
  patient: {},
  professionalId: 0,
  selectedCheckBox: "presencial",
  overAppointment: false,
  fromTime: timeFormatter("00:00:00"),
};
function reducer(state, action) {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.value };
    case "SET_PATIENT":
      return { ...state, patient: action.value };
    case "RESET":
      return initialState;
    default:
      throw new Error();
  }
}

function NewScheduleRow({
  professional,
  refresh,
  openForm,
  date,
  existingSchedule,
  action,
  addAppointment,
  closeAction,
  professionalView = true,
  isSelfService = false,
  selfServicePatient,
}) {
  const auxEditing = Boolean(action);
  const auxPatient = existingSchedule
    ? {
      Id: existingSchedule.PatientId,
      name: existingSchedule.PatientName,
    }
    : {};

  const accountSettings = useContext(AccountContext);
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const [isLoading, setIsLoading] = useState(false);
  const [editing, setEditing] = useState(auxEditing);
  const [showForm, setShowForm] = useState(openForm ?? false);
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const { setHasUnsavedChanges } = useUnsavedChanges();

  function init(initialState) {
    return {
      ...initialState,
      serviceRow: existingSchedule?.ServiceId ?? "",
      site: existingSchedule?.SiteId ?? "",
      room: existingSchedule?.RoomId ?? "",
      currentDate:
        existingSchedule?.AppointmentDateTime ??
        moment.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      coPaymentCostPaid: existingSchedule?.CoPaymentCostPaid ?? 0,
      healthInsurance: existingSchedule?.HealthInsuranceId ?? "",
      careModality: isSelfService
        ? selfServicePatient.CareModalityId
        : existingSchedule?.CareModalityId ?? "",
      assistance: existingSchedule?.AssistanceId ?? "",
      patient: auxPatient ?? {},
      selectedCheckBox: accountSettings.hasOnlineMeetingsEnabled && existingSchedule?.IsOnline
        ? "virtual"
        : "presencial",
      overAppointment: addAppointment,
      professionalId: existingSchedule?.ProfessionalId ?? professional.Id,
    };
  }

  const handleFromTimeChange = useCallback(
    (event) => {
      setHasUnsavedChanges(true);
      const value = event.target.value;
      const [hours, minutes] = value.split(":");
      const newDate = moment(date)
        .add(hours, "hour")
        .add(minutes, "minute")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      dispatch({ type: "SET_FIELD", field: "currentDate", value: newDate });
      dispatch({ type: "SET_FIELD", field: "fromTime", value });
    },
    [setHasUnsavedChanges, date]
  );

  const handleSaveClick = useCallback(
    async (event) => {
      setIsLoading(true);
      const isFormValid = form.current.checkValidity();
      setValidated(!isFormValid);
      if (!isFormValid) {
        event.stopPropagation();
        setIsLoading(false);
        return;
      }
      try {
        if (!professionalView) {
          const selfServiceAppointment = new SelfserviceAppointment(
            state.careModality,
            state.serviceRow,
            state.currentDate,
            state.site,
            state.assistance,
            state.selectedCheckBox === "virtual"
          );
          const newSelfserviceAppointment = await postSelfServiceCreateApi(
            selfServiceAppointment,
            professional.Id
          );
          if (newSelfserviceAppointment) {
            refresh();
          }
        } else {
          let schedule = new Appointments(
            state.patient.Id,
            state.professionalId,
            state.careModality,
            state.serviceRow,
            state.currentDate,
            state.site,
            state.assistance,
            state.selectedCheckBox === "virtual",
            state.room,
            state.healthInsurance,
            state.coPaymentCostPaid
          );
          if (!editing) {
            const newSchedule = await postScheduleApi(schedule);
            if (newSchedule && closeAction) {
              closeAction();
            }
          } else {
            const key = { type: "guid", value: existingSchedule.Id };
            await putScheduleApi({ key }, schedule);
            if (action) action();
          }
          refresh();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setHasUnsavedChanges(false);
        setIsLoading(false);
        setTimeout(function () {
          setIsLoading(false);
        }, 1000);
      }
    },
    [
      professionalView,
      editing,
      existingSchedule,
      action,
      closeAction,
      refresh,
      state,
    ]
  );

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const conditions = useMemo(() => {
    return state.careModality
      ? { CareModalityId: { eq: { type: "guid", value: state.careModality } } }
      : null;
  }, [state.careModality]);

  const handleCopaymentChange = (event) => {
    setHasUnsavedChanges(true);
    dispatch({ type: "SET_FIELD", field: "coPaymentCostPaid", value: event });
  };

  const handleCheckBoxChange = ({ target: { value } }) => {
    setHasUnsavedChanges(true);
    dispatch({ type: "SET_FIELD", field: "selectedCheckBox", value: value });
  };

  const handleCloseForm = () => {
    handleShowForm();
    if (state.overAppointment) {
      dispatch({ type: "SET_FIELD", field: "overAppointment", value: false });
    }
    if (action) {
      action();
    }
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    if (state.selectedCheckBox === "virtual") {
      dispatch({ type: "SET_FIELD", field: "room", value: "" });
      dispatch({ type: "SET_FIELD", field: "site", value: "" });
    }
  }, [state.selectedCheckBox]);

  const appointmentForm = () => {
    return (
      <Form noValidate validated={validated} key="formSchedule" ref={form}>
        <>
          {!isSelfService && (
            <Row className="g-2">
              {professionalView && (
                <Col>
                  <ItemSelector
                    entity={Entity.Paciente}
                    setEntity={(selectedPatient) =>
                      dispatch({ type: "SET_PATIENT", value: selectedPatient })
                    }
                    initialValue={state.patient.Id}
                    onEntitySelected={(selectedPatient) => {
                      dispatch({
                        type: "SET_FIELD",
                        field: "careModality",
                        value: selectedPatient.CareModalityId,
                      });
                      dispatch({
                        type: "SET_FIELD",
                        field: "healthInsurance",
                        value: selectedPatient.HealthInsuranceId,
                      });
                    }}
                  />
                </Col>
              )}
              <Col>
                <FormattedDropdown
                  apiEndpoint={Entity.Asistencia}
                  label={Entity.Asistencia}
                  required={false}
                  name="Assistance"
                  value={state.assistance}
                  onChange={(newAssitance) =>
                    dispatch({
                      type: "SET_FIELD",
                      field: "assistance",
                      value: newAssitance,
                    })
                  }
                />
              </Col>
              <Col>
                <FormattedDropdown
                  apiEndpoint={Entity.Consultorio}
                  label={Entity.Consultorio}
                  required={false}
                  name="Room"
                  value={state.room}
                  onChange={(newRoom) =>
                    dispatch({
                      type: "SET_FIELD",
                      field: "room",
                      value: newRoom,
                    })
                  }
                  disabled={state.selectedCheckBox === "virtual"}
                />
              </Col>
              <Col>
                <FormattedDropdown
                  apiEndpoint={Entity.ObraSocial}
                  label="Obra social"
                  required={true}
                  name="HealthInsurance"
                  value={state.healthInsurance}
                  onChange={(newHealthInsurance) =>
                    dispatch({
                      type: "SET_FIELD",
                      field: "healthInsurance",
                      value: newHealthInsurance,
                    })
                  }
                />
              </Col>
              <Col>
                <FormattedCurrencyInput
                  label="Copago Pago"
                  required={false}
                  name="coPaymentCostPaid"
                  value={state.coPaymentCostPaid}
                  onChange={handleCopaymentChange}
                  disabledTooltip={true}
                />
              </Col>
            </Row>
          )}
          <Row className="g-2 align-items-center">
            {addAppointment && (
              <Col>
                <FormattedTextBox
                  id="From"
                  label="Turno"
                  type="time"
                  handleChange={handleFromTimeChange}
                  value={timeFormatter(state.fromTime)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    step: 300,
                  }}
                  required
                />
              </Col>
            )}
            {!isSelfService && (
              <Col>
                <FormattedDropdown
                  apiEndpoint={Entity.ModalidadAtencion}
                  label="Modalidad de atención"
                  required={true}
                  name="CareModalitySelfService"
                  value={state.careModality}
                  onChange={(newCareModality) =>
                    dispatch({
                      type: "SET_FIELD",
                      field: "careModality",
                      value: newCareModality,
                    })
                  }
                />
              </Col>
            )}
            <Col>
              <FormattedDropdown
                apiEndpoint={Entity.Prestacion}
                label="Prestacion"
                required={true}
                name="serviceRowSelfService"
                value={state.serviceRow}
                useConditions={true}
                conditions={conditions}
                onChange={(newServiceRow) =>
                  dispatch({
                    type: "SET_FIELD",
                    field: "serviceRow",
                    value: newServiceRow,
                  })
                }
              />
            </Col>
            <Col>
              <FormattedDropdown
                apiEndpoint={Entity.Sede}
                label={Entity.Sede}
                required={state.selectedCheckBox !== "virtual"}
                name="SiteSelfService"
                value={state.site}
                onChange={(newSite) =>
                  dispatch({ type: "SET_FIELD", field: "site", value: newSite })
                }
                disabled={state.selectedCheckBox === "virtual"}
              />
            </Col>
            <Col>
              {accountSettings.hasOnlineMeetingsEnabled && (
                <label style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="tipo"
                    value="virtual"
                    checked={state.selectedCheckBox === "virtual"}
                    onChange={handleCheckBoxChange}
                    disabled={!accountSettings.hasOnlineMeetingsEnabled}
                  />
                  <span style={{ marginLeft: "5px" }}>Virtual</span>
                </label>
              )}
              <label style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  name="tipo"
                  value="presencial"
                  checked={state.selectedCheckBox === "presencial"}
                  onChange={handleCheckBoxChange}
                />
                <span style={{ marginLeft: "5px" }}>Presencial</span>
              </label>
            </Col>
          </Row>
        </>
      </Form>
    );
  };

  return (
    <div>
      {!addAppointment ? (
        !showForm ? (
          <ToolBar
            showNewButtonWithAction={true}
            newAction={() => handleShowForm()}
            disabled={isLoading}
            title={state.currentDate.slice(11, 16)}
            titleTag="h4"
          />
        ) : (
          <ToolBar
            showSaveButton={true}
            showCloseButton={true}
            actionClose={() => handleCloseForm()}
            disabled={isLoading}
            saveAction={handleSaveClick}
            title={state.currentDate.slice(11, 16)}
            titleTag="h4"
          />
        )
      ) : (
        <ToolBar
          showSaveButton={true}
          showCloseButton={true}
          actionClose={() => closeAction()}
          disabled={isLoading}
          saveAction={handleSaveClick}
        />
      )}
      <Row>
        <div>{showForm && <div className="mt-3">{appointmentForm()}</div>}</div>
      </Row>
    </div>
  );
}

export default React.memo(NewScheduleRow);
