import React, { createContext, useState, useEffect } from 'react';
import GetAccountDetails from '../Api/Controllers/Accounts/GetAccountDetails';
import { Spinner } from 'react-bootstrap';

export const BrandingContext = createContext();

export const BrandingProvider = ({ children }) => {
  const [brandingDetails, setBrandingDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadBrandingDetails = async () => {
      try {
        const details = await GetAccountDetails();

        if (details) {
          setBrandingDetails(details);
          document.title = details.Name;
        } 
      } catch (error) {
        console.error("Error fetching branding details: ", error);
      } finally {
        setLoading(false);
      }
    };

    loadBrandingDetails();
  }, []);

  const updateBrandingColor = (colorType, colorValue) => {
    setBrandingDetails((prevDetails) => ({
      ...prevDetails,
      [colorType]: colorValue
    }));
  };

  if (loading) {
    return (
      <div className="App">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <BrandingContext.Provider value={{ brandingDetails, updateBrandingColor }}>
      {children}
    </BrandingContext.Provider>
  );
};
