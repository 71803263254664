import { Container, Card, Row, Col, Button, Spinner, Form } from "react-bootstrap";
import FormattedTextBox from "../GenericComponents/FormattedTextBox";
import { Headphones, EyeSlash, Eye } from "react-bootstrap-icons";
import CardTableSearch from "../TableSearch/CardTableSearch";
import Toolbar from "../GenericComponents/ToolBar";
import { useState, useEffect, useContext } from "react";
import GetEntities from "../GenericComponents/Entities";
import {
  GetEndPoint,
  PutEndPoint,
  PostEndPoint,
} from "../Api/Controllers/Common/EndPoints";
import SuccessEvent from "../GenericComponents/SuccessEvent";
import FillEntity from "../GenericComponents/FillEntity";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";
import ZoomDetails from "../../Models/Zoom/ZoomDetails";
import AttachmentFiles from "../GenericComponents/AttachmentFiles";
import PostUploadedFiles from "../Api/Controllers/UploadedFiles/PostUploadedFiles";
import DeleteUploadedFiles from "../Api/Controllers/UploadedFiles/DeleteUploadedFiles";
import PhoneNumberValidator from "../GenericComponents/PhoneNumberValidator";
import { BrandingContext } from "../Context/BrandingContext";

const Entities = GetEntities();

function ConfigurationPage() {
  const { brandingDetails, updateBrandingColor } = useContext(BrandingContext);
  const [isLoading, setIsLoading] = useState(false);
  const [zoomClientId, setZoomClientId] = useState("");
  const [zoomClientSecret, setZoomClientSecret] = useState("");
  const [zoomSdkKey, setZoomSdkKey] = useState("");
  const [zoomSdkSecret, setZoomSdkSecret] = useState("");
  const { setHasUnsavedChanges } = useUnsavedChanges();
  const [account, setAccount] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [showLCPrivateKey, setShowLCPrivateKey] = useState(false);
  const [showZoomClientSecret, setShowZoomClientSecret] = useState(false);
  const [showZoomSdkSecret, setShowZoomSdkSecret] = useState(false);

  const [attachmentFileLogo, setAttachmentFileLogo] = useState([]);
  const [attachmentFileLoginLogo, setAttachmentFileLoginLogo] = useState([]);
  const [woSavingAttachmentFilesLogo, setWoSavingAttachmentFilesLogo] = useState([]);
  const [woSavingAttachmentFilesLoginLogo, setWoSavingAttachmentFilesLoginLogo] = useState([]);
  const [deleteAttachmentFilesLogo, setDeleteAttachmentFilesLogo] = useState([]);
  const [deleteAttachmentFilesLoginLogo, setDeleteAttachmentFilesLoginLogo] = useState([]);

  const fetchData = async () => {
    const accountData = GetEndPoint(Entities.Cuenta);
    const response = await accountData();
    if (response && !response.error) {
      setAccount(FillEntity(Entities.Cuenta, response));
    }
  };

  const saveAttachments = async (id, type) => {
    const obj = { relatedEntityId: id, uploadedFileType: type === 'Logo' ? 3 : 6 };
    let formData = new FormData();
  
    if (type === 'Logo') {
      woSavingAttachmentFilesLogo.forEach((file) => formData.append("files", file));
      await PostUploadedFiles(obj, formData);
      setWoSavingAttachmentFilesLogo([]);
    } else if (type === 'LoginLogo') {
      woSavingAttachmentFilesLoginLogo.forEach((file) => formData.append("files", file));
      await PostUploadedFiles(obj, formData);
      setWoSavingAttachmentFilesLoginLogo([]);
    }
  
    setRefresh(!refresh);
    setHasUnsavedChanges(false);
  };
  
  const deleteAttachments = (type) => {
    const filesToDelete = type === 'Logo' ? deleteAttachmentFilesLogo : deleteAttachmentFilesLoginLogo;
  
    filesToDelete.forEach(async (file) => {
      const obj = { relatedEntityId: file };
      await DeleteUploadedFiles(obj);
    });
  
    if (type === 'Logo') {
      setDeleteAttachmentFilesLogo([]);
    } else if (type === 'LoginLogo') {
      setDeleteAttachmentFilesLoginLogo([]);
    }
  };
  

  const handleInputChange = (e, key) => {
    const value = e.target.value;
  
    setAccount((prevState) => ({ ...prevState, [key]: value }));
    setHasUnsavedChanges(true);
  
    if (key === "BrandingPrimaryColor" || key === "BrandingSecondaryColor") {
      updateBrandingColor(key, value);
    }
  };
  

  const toggleShowLCPrivateKey = () => {
    setShowLCPrivateKey(!showLCPrivateKey);
  };

  const toggleShowZoomClientSecret = () => {
    setShowZoomClientSecret(!showZoomClientSecret);
  };

  const toggleShowZoomSdkSecret = () => {
    setShowZoomSdkSecret(!showZoomSdkSecret);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const key = { type: "guid", value: account.Id };
      const api = PutEndPoint(Entities.Cuenta);
      await api({ key }, account);
  
      if (woSavingAttachmentFilesLogo.length > 0) {
        await saveAttachments(account.Id, 'Logo');
      }
      if (woSavingAttachmentFilesLoginLogo.length > 0) {
        await saveAttachments(account.Id, 'LoginLogo');
      }
  
      if (deleteAttachmentFilesLogo.length > 0) {
        deleteAttachments('Logo');
      }
      if (deleteAttachmentFilesLoginLogo.length > 0) {
        deleteAttachments('LoginLogo');
      }
  
      SuccessEvent("Configuracion guardada con exito");
      setHasUnsavedChanges(false);
      setRefresh(!refresh);
    } catch (error) {
      console.error("Error saving account:", error);
    }
    setTimeout(() => setIsLoading(false), 1000);
    setIsLoading(false);
  };
  

  const handleConnectClick = async () => {
    const postZoomDetails = PostEndPoint(Entities.CuentasZoom);

    let zoomDetailsBody = new ZoomDetails(
      zoomClientId,
      zoomClientSecret,
      zoomSdkKey,
      zoomSdkSecret
    );

    const response = await postZoomDetails(zoomDetailsBody);

    if (response && !response.error) {
      window.location.replace(response.ZoomUrl);
    } else {
      console.error("Error adding Zoom configuration:", response.error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  if (isLoading) {
    return <Spinner animation="border" variant="secondary" />;
  }

  return (
    <Container>
      <Toolbar
        title={"Configuracion"}
        entity={Entities.Cuenta}
        saveAction={handleSave}
        showBackButton={false}
        showSaveButton={true}
        disabled={false}
        refresh={refresh}
        setRefresh={setRefresh}
        showRefreshButton={true}
      />
      <hr />
      <Card className="mb-4">
        <Card.Header>
          <h2>Datos Generales</h2>
        </Card.Header>
        <Card.Body>
          <div className="mt-3">
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Nombre"
                  type="text"
                  placeholder=""
                  name="name"
                  value={account.Name}
                  disabled={true}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="URL"
                  type="text"
                  placeholder=""
                  name="name"
                  value={account.Url}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Numero de Telefono"
                  type="text"
                  placeholder=""
                  name="PhoneNumber"
                  value={account.PhoneNumber}
                  disabled={false}
                  handleChange={(e) => handleInputChange(e, "PhoneNumber")}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Email"
                  type="Email"
                  placeholder=""
                  name="Email"
                  value={account.Email}
                  disabled={false}
                  handleChange={(e) => handleInputChange(e, "Email")}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Web Site Url"
                  type="text"
                  placeholder=""
                  name="WebSiteUrl"
                  value={account.WebSiteUrl}
                  disabled={false}
                  handleChange={(e) => handleInputChange(e, "WebSiteUrl")}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Entidad Legal"
                  type="text"
                  placeholder=""
                  name="LegalName"
                  value={account.LegalName}
                  disabled={false}
                  handleChange={(e) => handleInputChange(e, "LegalName")}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <FormattedTextBox
                label="Direcciones"
                type="textarea"
                placeholder=""
                name="FullAddressInformation"
                value={account.FullAddressInformation}
                disabled={false}
                handleChange={(e) =>
                  handleInputChange(e, "FullAddressInformation")
                }
              />
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Header>
          <h2>Whatsapp</h2>
        </Card.Header>
        <Card.Body>
          <div className="mt-3">
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Live Connect Access Key"
                  type="text"
                  placeholder=""
                  name="SettingLiveConnectAccessKey"
                  value={account.SettingLiveConnectAccessKey}
                  disabled={false}
                  handleChange={(e) =>
                    handleInputChange(e, "SettingLiveConnectAccessKey")
                  }
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Live Connect Private Key"
                  type={showLCPrivateKey ? "text" : "password"}
                  placeholder=""
                  name="SettingLiveConnectPrivateKey"
                  value={account.SettingLiveConnectPrivateKey}
                  disabled={false}
                  handleChange={(e) =>
                    handleInputChange(e, "SettingLiveConnectPrivateKey")
                  }
                >
                  <Button
                    variant="link"
                    onClick={toggleShowLCPrivateKey}
                    style={{
                      color: "inherit",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {showLCPrivateKey ? (
                      <EyeSlash className="mx-2" size={20} />
                    ) : (
                      <Eye className="mx-2" size={20} />
                    )}
                  </Button>
                </FormattedTextBox>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedTextBox
                  label="Live Connect Channel Id"
                  type="text"
                  placeholder=""
                  name="SettingLiveConnectChannelId"
                  value={account.SettingLiveConnectChannelId}
                  disabled={false}
                  handleChange={(e) =>
                    handleInputChange(e, "SettingLiveConnectChannelId")
                  }
                />
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Header>
          <h2>Atencion virtual - Google Meet</h2>
        </Card.Header>
        <Card.Body>
          <div className="mt-3">
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Credencial de Service Account"
                  as="textarea"
                  placeholder=""
                  name="name"
                  value={account.GoogleCloudServiceAccountCredential}
                  disabled={false}
                  handleChange={(e) =>
                    handleInputChange(e, "GoogleCloudServiceAccountCredential")
                  }
                />
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Header>
          <h2>Atencion virtual - Zoom</h2>
        </Card.Header>
        <Card.Body>
          <div className="mt-3">
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Zoom Client Id"
                  type="text"
                  placeholder=""
                  name="name"
                  value={""}
                  disabled={false}
                  handleChange={(e) => setZoomClientId(e.target.value)}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Zoom Client Secret"
                  type={showZoomClientSecret ? "text" : "password"}
                  placeholder=""
                  name="name"
                  value={""}
                  disabled={false}
                  handleChange={(e) => setZoomClientSecret(e.target.value)}
                >
                  <Button
                    variant="link"
                    onClick={toggleShowZoomClientSecret}
                    style={{
                      color: "inherit",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {showZoomClientSecret ? (
                      <EyeSlash className="mx-2" size={20} />
                    ) : (
                      <Eye className="mx-2" size={20} />
                    )}
                  </Button>
                </FormattedTextBox>
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <FormattedTextBox
                  label="Zoom Sdk key"
                  type="text"
                  placeholder=""
                  name="name"
                  value={""}
                  disabled={false}
                  handleChange={(e) => setZoomSdkKey(e.target.value)}
                />
              </Col>
              <Col>
                <FormattedTextBox
                  label="Zoom Sdk Secret"
                  type={showZoomSdkSecret ? "text" : "password"}
                  placeholder=""
                  name="name"
                  value={""}
                  disabled={false}
                  handleChange={(e) => setZoomSdkSecret(e.target.value)}
                >
                  <Button
                    variant="link"
                    onClick={toggleShowZoomSdkSecret}
                    style={{
                      color: "inherit",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {showZoomClientSecret ? (
                      <EyeSlash className="mx-2" size={20} />
                    ) : (
                      <Eye className="mx-2" size={20} />
                    )}
                  </Button>
                </FormattedTextBox>
              </Col>
              <Col xs={2} style={{ paddingTop: "7px", paddingRight: "20px" }}>
                <Button variant="success" onClick={handleConnectClick}>
                  <Headphones title="Add" />
                </Button>
                <b style={{ marginLeft: "12px" }}>Conectar</b>
              </Col>
            </Row>
            <Row>
              <CardTableSearch
                entity={Entities.CuentasZoom}
                key={Entities.CuentasZoom}
                showNewButton={false}
              />
            </Row>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header>
          <h2>Configuracion de colores</h2>
        </Card.Header>
        <Card.Body>
          <Row className="g-2">
            <Col>
              <Form.Label htmlFor="primaryColorInput">Color Primario</Form.Label>
              <Form.Control
                type="color"
                id="primaryColorInput"
                value={account.BrandingPrimaryColor || "#000000"}
                title="Choose your primary color"
                onChange={(e) => handleInputChange(e, "BrandingPrimaryColor")}
              />
            </Col>
            <Col>
              <Form.Label htmlFor="secondaryColorInput">Color Secundario</Form.Label>
              <Form.Control
                type="color"
                id="secondaryColorInput"
                value={account.BrandingSecondaryColor || "#000000"}
                title="Choose your secondary color"
                onChange={(e) => handleInputChange(e, "BrandingSecondaryColor")}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row className="mb-3">
        <AttachmentFiles
          userId={account.Id}
          attachmentFiles={attachmentFileLogo}
          setAttachmentFiles={setAttachmentFileLogo}
          woSavingAttachmentFiles={woSavingAttachmentFilesLogo}
          setWoSavingAttachmentFiles={setWoSavingAttachmentFilesLogo}
          deleteAttachmentFiles={deleteAttachmentFilesLogo}
          setDeleteAttachmentFiles={setDeleteAttachmentFilesLogo}
          refresh={refresh}
          uploadedFileType={3}
          singleAttachment={true}
        />
      </Row>
      <Row className="mb-3">
        <AttachmentFiles
          userId={account.Id}
          attachmentFiles={attachmentFileLoginLogo}
          setAttachmentFiles={setAttachmentFileLoginLogo}
          woSavingAttachmentFiles={woSavingAttachmentFilesLoginLogo}
          setWoSavingAttachmentFiles={setWoSavingAttachmentFilesLoginLogo}
          deleteAttachmentFiles={deleteAttachmentFilesLoginLogo}
          setDeleteAttachmentFiles={setDeleteAttachmentFilesLoginLogo}
          refresh={refresh}
          uploadedFileType={6}
          singleAttachment={true}
        />
      </Row>
    </Container>
  );
}

export default ConfigurationPage;
