import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { ExpandMore, ChevronRight, ExpandLess } from "@mui/icons-material";
import fetchCategories from "./NavigatorData";
import { useContext } from "react";
import { PopupContext } from "./PopupContext";
import { useUnsavedChanges } from "../Context/UnsavedChangesContext";
import UnsavedChangesWarning from "../GenericComponents/UnsavedChangesWarning";
import { BrandingContext } from "../Context/BrandingContext";

export default function Navigator(props) {
  const { ...other } = props;
  const location = useLocation();
  const { isPopupOpen } = useContext(PopupContext);
  const [categories, setCategories] = useState([]);
  const { hasUnsavedChanges, resetUnsavedChanges } = useUnsavedChanges();
  const { brandingDetails, updateBrandingColor } = useContext(BrandingContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories().then((categories) => {
      setCategories(categories);
      if (categories && categories.length > 0) {
        const firstCategory = categories[0];
        props.firstCategory(firstCategory);
      }
    });
  }, []);

  const [openCategory, setOpenCategory] = useState("");
  const [openSubCategory, setOpenSubCategory] = useState("");

  const handleCategoryClick = (name) => {
    setOpenCategory(name === openCategory ? "" : name);
  };

  const handleSubCategoryClick = (name) => {
    setOpenSubCategory(name === openSubCategory ? "" : name);
  };

  const handleLinkClick = async (path, event) => {
    event.preventDefault();
    if (hasUnsavedChanges) {
        try {
            await UnsavedChangesWarning();
            resetUnsavedChanges();
            navigate(path);
        } catch {
        }
    } else {
        navigate(path);
    }
};

  const renderChildren = (children, level) => {
    if (!children) return null;

    return children.map(({ name, icon, path, children: children }) => {
      const haschildren = children && children.length > 0;

      return (
        <Box key={name} sx={{ marginLeft: 2 * level }}>
          <Link
            to={path}
            key={name}
            style={{ textDecoration: "none", color: brandingDetails.BrandingSecondaryColor }}
            onClick={(event) => handleLinkClick(path, event)}
          >
            <ListItem
              disablePadding
              onClick={() => handleSubCategoryClick(name)}
            >
              <ListItemButton
                style={{ paddingBottom: "0px", paddingTop: "0px" }}
                selected={location.pathname === path}
                sx={{
                  ...(location.pathname === path && {
                    backgroundColor: "#0000",
                  }),
                }}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText>{name}</ListItemText>
                {haschildren && (
                  <ListItemIcon>
                    {openSubCategory === name ? (
                      <ExpandLess />
                    ) : (
                      <ChevronRight />
                    )}
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          </Link>
          {haschildren && (
            <>
              {openSubCategory === name && renderChildren(children, level + 1)}
            </>
          )}
        </Box>
      );
    });
  };

  return (
    <Drawer
      variant="permanent"
      {...other}
      style={{ opacity: isPopupOpen ? 0.5 : 1 }}
    >
      <List disablePadding>
        <ListItem sx={{ fontSize: 22, color: brandingDetails.BrandingSecondaryColor }}>
          <Box sx={{ width: 180, height: 180, margin: "auto" }}>
            <img
              src={brandingDetails?.LogoUrl || ""}
              alt="Company logo"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </ListItem>
        {categories.map(({ name, icon, path, children }) => (
          <Box key={name} sx={{ bgcolor: brandingDetails?.BrandingPrimaryColor }}>
            <Link
              to={path}
              key={name}
              style={{ textDecoration: "none", color: brandingDetails.BrandingSecondaryColor }}
              onClick={(event) => handleLinkClick(path, event)}
            >
              <ListItemButton
                selected={location.pathname === path}
                style={{ paddingBottom: "0px", paddingTop: "0px" }}
                sx={{
                  px: 3,
                  ...(location.pathname === path && {
                    backgroundColor: "#333",
                  }),
                }}
                onClick={() => handleCategoryClick(name)}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText>{name}</ListItemText>
                {children.length > 0 &&
                  (openCategory === name ? <ExpandMore /> : <ChevronRight />)}
              </ListItemButton>
            </Link>
            {openCategory === name && renderChildren(children, 1)}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
