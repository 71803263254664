import React, { useEffect, useCallback, useReducer, useContext, useMemo } from 'react';
import { Row, Col, Card, Container, ListGroup, Button, Spinner } from "react-bootstrap";
import dayjs from 'dayjs';
import FormattedDropdown from '../GenericComponents/FormattedDropdown';
import GetEntities from '../GenericComponents/Entities';
import Listview from '../GenericComponents/Listview'
import { GetEndPoint, PostEndPoint } from "../Api/Controllers/Common/EndPoints"
import NewScheduleRow from './NewScheduleRow';
import ExistingSchedule from './ExistingSchedule';
import { GetProfessionalsForAppointmentsRequest } from '../../Models/Appointments/GetProfessionalsForAppointments';
import MultiSelect from '../GenericComponents/MultiSelect';
import FillEntity from '../GenericComponents/FillEntity';
import { UserOperationsAllowedContext } from '../Dashboard/UserOperationsAllowedContext';
import SpanishDateCalendar from '../GenericComponents/SpanishDateCalendar';

const Entity = GetEntities();
const emptyUuid = "00000000-0000-0000-0000-000000000000";

const initialState = {
    date: dayjs(),
    professionalSchedule: [],
    specialty: '',
    careModality: '',
    professional: null,
    professionalList: [],
    addAppointment: false,
    selectServices: [],
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_DATE':
            return { ...state, date: action.payload, professionalSchedule: [] };
        case 'SET_PROFESSIONAL_SCHEDULE':
            return { ...state, professionalSchedule: action.payload, isLoading: false };
        case 'SET_SPECIALTY':
            return { ...state, specialty: action.payload, professionalSchedule: [] };
        case 'SET_CAREMODALITY':
            return { ...state, careModality: action.payload, professionalSchedule: [] };
        case 'SET_PROFESSIONAL':
            return { ...state, professional: action.payload, professionalSchedule: [], isLoading: true };
        case 'SET_PROFESSIONAL_LIST':
            return { ...state, professionalList: action.payload };
        case 'SET_ADD_APPOINTMENT':
            return { ...state, addAppointment: action.payload };
        case 'SET_SELECTED_SERVICES':
            return { ...state, selectServices: action.payload, professionalSchedule: [] };
        case 'TOGGLE_REFRESH':
            return { ...state, refresh: !state.refresh, professionalSchedule: [] };
        case 'SET_LOADING':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

const Appointment = ({ requiredOperations = [] }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const currentDate = state.date.format('YYYY-MM-DD');
    const { userOperationsAllowed, hasAllRequiredOperations } = useContext(UserOperationsAllowedContext);

    const handleClickProfessional = useCallback(async (professionalClicked) => {
        dispatch({ type: 'SET_PROFESSIONAL', payload: professionalClicked });
        dispatch({ type: 'SET_LOADING', payload: true });
        const apiProfesionalAppointmentSchedule = GetEndPoint(Entity.AgendaProfesionalConTurno);
        const response = await apiProfesionalAppointmentSchedule(currentDate, professionalClicked.Id);
        dispatch({ type: 'SET_PROFESSIONAL_SCHEDULE', payload: response });
    }, [currentDate]);

    const handleRefresh = useCallback(() => {
        dispatch({ type: 'TOGGLE_REFRESH' });
    }, [dispatch]);

    const userAllowed = useMemo(() => hasAllRequiredOperations({
        requiredOperations,
        userOperations: userOperationsAllowed
    }), [requiredOperations, userOperationsAllowed]);

    useEffect(() => {
        const fetchData = async () => {
            const apiProfesionalAppointment = PostEndPoint(Entity.AgendaProfesional);
            const body = new GetProfessionalsForAppointmentsRequest(state.specialty, state.selectServices);
            const response = await apiProfesionalAppointment(currentDate, body);
            let profesionalAppointment = response.map(a => FillEntity(Entity.AgendaProfesional, a));
            dispatch({ type: 'SET_PROFESSIONAL_LIST', payload: profesionalAppointment });
        };

        fetchData();
        if (state.professional) {
            handleClickProfessional(state.professional);
        }
    }, [state.date, state.specialty, state.refresh, state.selectServices, handleClickProfessional]);

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h2>Turnos</h2>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Card style={{ width: '320px' }}>
                                <SpanishDateCalendar
                                    value={state.date}
                                    onChange={(newDate) => dispatch({ type: 'SET_DATE', payload: newDate })}
                                />
                            </Card>
                        </Col>
                        <Col>
                            <Row className="g-2">
                                <FormattedDropdown
                                    apiEndpoint={Entity.Especialidad}
                                    label={Entity.Especialidad}
                                    required={true}
                                    name="GenericId"
                                    value={state.specialty}
                                    onChange={(newSpecialty) => dispatch({ type: 'SET_SPECIALTY', payload: newSpecialty })}
                                />
                            </Row>
                            <Row className="g-2">
                                <FormattedDropdown
                                    apiEndpoint={Entity.ModalidadAtencion}
                                    label="Modalidad de atencion"
                                    required={true}
                                    name="GenericId"
                                    value={state.careModality}
                                    onChange={(newcareModality) => dispatch({ type: 'SET_CAREMODALITY', payload: newcareModality })}
                                />
                            </Row>
                            <Row className="g-2  mb-3">
                                <Col>
                                    <MultiSelect
                                        apiEndpoint={Entity.Prestacion}
                                        useConditions={true}
                                        conditions={state.careModality ?
                                            { "CareModalityId": { eq: { type: 'guid', value: state.careModality } } } :
                                            null}
                                        selectedOptions={state.selectServices}
                                        setSelectedOptions={(newSelectedOptions) => {
                                            dispatch({ type: 'SET_SELECTED_SERVICES', payload: newSelectedOptions });
                                        }}
                                        labelComp="Prestaciones"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Listview
                                items={state.professionalList}
                                handleClick={handleClickProfessional}
                            />
                        </Col>
                        <Row className="g-2">
                            {state.professional && (
                                <h4>Esta viendo los turnos de {state.professional.Name} {' '} {state.professional.LastName} para el dia {state.date.format('DD/MM')}</h4>
                            )}
                        </Row>
                    </Row>
                    {state.isLoading && (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </Spinner>
                    )}
                    <Row className="g-2">
                        <ListGroup variant="flush">
                            {state.professionalSchedule.map((schedule, index) => (
                                <div key={index}>
                                    {schedule.Id !== emptyUuid ? (
                                        <ExistingSchedule
                                            schedule={schedule}
                                            refresh={handleRefresh}
                                            canDelete={userAllowed}
                                            canResendNotification={userAllowed}
                                            canEdit={userAllowed}
                                        />
                                    ) :
                                        (userAllowed && (<>
                                            <ListGroup.Item>
                                                <NewScheduleRow
                                                    specialty={state.specialty}
                                                    date={schedule.AppointmentDateTime}
                                                    professional={state.professional}
                                                    refresh={handleRefresh}
                                                />
                                            </ListGroup.Item>
                                        </>)
                                        )}
                                </div>
                            ))}
                        </ListGroup>
                    </Row>
                    {state.professional && userAllowed && (
                        <>
                            <Row>
                                <Button variant="primary" onClick={() => dispatch({ type: 'SET_ADD_APPOINTMENT', payload: true })}>
                                    Agregar sobreturno
                                </Button>
                            </Row>
                            <Row>
                                {state.addAppointment && (
                                    <NewScheduleRow
                                        specialty={state.specialty}
                                        openForm={true}
                                        date={dayjs(state.date).startOf('day').format()}
                                        professional={state.professional}
                                        refresh={handleRefresh}
                                        addAppointment={true}
                                        closeAction={() => dispatch({ type: 'SET_ADD_APPOINTMENT', payload: false })}
                                    />
                                )}
                            </Row>
                        </>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Appointment;
