import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Spinner } from "react-bootstrap";
import FormattedDropdownWithButton from "../GenericComponents/FormattedDropdownWithButton";
import GetSpecialty from "../Api/Controllers/Specialties/GetSpecialty";
import GetEntities from "../GenericComponents/Entities";
import PutProfessional from "../Api/Controllers/Professional/PutProfessional";
import GetProfessional from "../Api/Controllers/Professional/GetProfessional";
import Toolbar from "../GenericComponents/ToolBar";
import DeleteWarning from "../GenericComponents/DeleteWarning";
import FillEntity from "../GenericComponents/FillEntity";
import FormattedTextBox from "../GenericComponents/FormattedTextBox";
import AttachmentFiles from "../GenericComponents/AttachmentFiles";
import PostUploadedFiles from "../Api/Controllers/UploadedFiles/PostUploadedFiles";
import DeleteUploadedFiles from "../Api/Controllers/UploadedFiles/DeleteUploadedFiles";

const specialtyEntity = GetEntities().Especialidad;
const Entity = GetEntities();

const getEmptyProfessional = async () => {
  return {
    SpecialtyId: "",
  };
};

const ProfesionalSpecialties = ({ professionalId, userAllowed }) => {
  const [professional, setProfessional] = useState(getEmptyProfessional());
  const [specialties, setSpecialties] = useState([]);
  const [refreshDropDown, setrefreshDropDown] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [woSavingAttachmentFiles, setWoSavingAttachmentFiles] = useState([]);
  const [deleteAttachmentFiles, setDeleteAttachmentFiles] = useState([]);

  const deleteSpecialtyForProfessional = async (professional) => {
    professional.SpecialtyId = "";
    const key = { type: "guid", value: professionalId };
    await PutProfessional({ key }, professional);
    setRefresh(!refresh);
  };

  const saveAttachments = async (id) => {
    const obj = { relatedEntityId: id, uploadedFileType: 2 };
    let formData = new FormData();
    woSavingAttachmentFiles.map((file) => {
      return formData.append("files", file);
    });

    await PostUploadedFiles(obj, formData);
    setWoSavingAttachmentFiles([]);
    setRefresh(!refresh);
  };

  const deleteAttachments = () => {
    deleteAttachmentFiles.map(async (file) => {
      const obj = { relatedEntityId: file };
      await DeleteUploadedFiles(obj);
    });
  };

  const handleChange = (event) => {
    setProfessional({
      ...professional,
      [event.target.name]: event.target.value,
    });
  };

  const getProfessional = async (professionalId) => {
    const key = { type: "guid", value: professionalId };
    const response = await GetProfessional({ key });
    const responseProfessional = FillEntity(Entity.Profesional, response);
    setProfessional(responseProfessional);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const key = { type: "guid", value: professionalId };
    await PutProfessional({ key }, professional);
    if (woSavingAttachmentFiles.length > 0) {
      await saveAttachments(professionalId);
    }
    if (deleteAttachmentFiles.length > 0) {
      deleteAttachments();
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
    setrefreshDropDown(!refreshDropDown);
  };

  const fetchSpecility = async () => {
    const response = await GetSpecialty();
    const speciality = response.value.map((speciality) =>
      FillEntity(specialtyEntity, speciality)
    );
    setSpecialties(speciality);
  };

  const fillDropdowns = () => {
    fetchSpecility();
  };

  useEffect(() => {
    fillDropdowns();
    getProfessional(professionalId);
  }, [refreshDropDown, refresh]);

  if (isLoading) {
    return <Spinner animation="border" variant="secondary" />;
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          <Toolbar
            title={"Datos profesionales"}
            entity={specialtyEntity}
            deleteAction={() =>
              DeleteWarning(() => deleteSpecialtyForProfessional(professional))
            }
            saveAction={handleSave}
            setRefresh={setRefresh}
            refresh={refresh}
            showBackButton={false}
            showSaveButton={userAllowed}
            showNewButton={userAllowed}
            showDeleteButton={userAllowed}
            showRefreshButton={true}
            disabled={isLoading}
          />
        </Card.Header>
        <Card.Body>
          <Container>
            <Row className="g-2  mb-3">
              <Col md>
                <FormattedDropdownWithButton
                  dropdownOptions={specialties}
                  label="Especialidad"
                  required={true}
                  name="SpecialtyId"
                  onChange={handleChange}
                  selected={professional.SpecialtyId}
                  entity={specialtyEntity}
                  setRefresh={setrefreshDropDown}
                  refresh={refreshDropDown}
                  popupentity="IdName"
                  key={specialtyEntity}
                  disabled={!userAllowed}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <Col md>
                <FormattedTextBox
                  label="Numero de matricula"
                  type="number"
                  name="RegistrationNumber"
                  value={professional.RegistrationNumber}
                  handleChange={handleChange}
                  required={false}
                  validated={false}
                  disabled={!userAllowed}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <Col md>
                <FormattedTextBox
                  label="Observaciones"
                  type="text"
                  name="Remarks"
                  value={professional.Remarks}
                  handleChange={handleChange}
                  required={false}
                  validated={false}
                  disabled={!userAllowed}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <Col md>
                <FormattedTextBox
                  label="CUIT"
                  type="text"
                  name="Cuit"
                  value={professional.Cuit}
                  handleChange={handleChange}
                  required={false}
                  validated={false}
                  disabled={!userAllowed}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <Col md>
                <FormattedTextBox
                  label="Cuenta de Google Meet"
                  type="email"
                  name="GoogleAccountEmail"
                  value={professional.GoogleAccountEmail}
                  handleChange={handleChange}
                  required={false}
                  validated={false}
                  disabled={!userAllowed}
                />
              </Col>
            </Row>
            <Row className="g-2">
              <Col md>
                <FormattedTextBox
                  label="Preserfar Medico"
                  type="number"
                  name="PreserfarMedicoId"
                  value={professional.PreserfarMedicoId}
                  handleChange={handleChange}
                  required={false}
                  validated={false}
                  disabled={!userAllowed}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Body>
          <AttachmentFiles
            userId={professionalId}
            attachmentFiles={attachmentFiles}
            setAttachmentFiles={setAttachmentFiles}
            woSavingAttachmentFiles={woSavingAttachmentFiles}
            setWoSavingAttachmentFiles={setWoSavingAttachmentFiles}
            deleteAttachmentFiles={deleteAttachmentFiles}
            setDeleteAttachmentFiles={setDeleteAttachmentFiles}
            refresh={refresh}
            isSignature={true}
            disabled={!userAllowed}
            uploadedFileType={2}
            singleAttachment={true}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProfesionalSpecialties;
