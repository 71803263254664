import React, { createContext, useState, useEffect } from 'react';
import GetEntities from '../GenericComponents/Entities';
import { GetEndPoint } from "../Api/Controllers/Common/EndPoints";

export const AccountContext = createContext();
const Entities = GetEntities();


export const AccountProvider = ({ children }) => {
    const [accountSettings, setAccountSettings] = useState({
        hasOnlineMeetingsEnabled: false,
        hasWhatsappIntegration: false,
        hasPatientLogin: false,
        hasProfessionalLogin: false,
        hasElectronicPrescriptions: false,
    });

    useEffect(() => {
        const fetchAccountSettings = async () => {
            try {
                const accountData = GetEndPoint(Entities.CuentaPublic);
                const response = await accountData();
                setAccountSettings({
                    hasOnlineMeetingsEnabled: response.HasOnlineMeetingsEnabled,
                    hasWhatsappIntegration: response.HasWhatsappIntegration,
                    hasPatientLogin: response.HasPatientLogin,
                    hasProfessionalLogin: response.HasProfessionalLogin,
                    hasElectronicPrescriptions: response.HasElectronicPrescriptions,
                });
            } catch (error) {
                console.error('Error fetching account settings:', error);
            }
        };

        fetchAccountSettings();
    }, []);

    return (
        <AccountContext.Provider value={accountSettings}>
            {children}
        </AccountContext.Provider>
    );
};
