import GetEntities from "./Entities";
import HealthInsurance from "../../Models/HealthInsurance/HealthInsurance";
import Patient from "../../Models/Patient/Patient";
import Profesional from "../../Models/Profesional/Profesional";
import Account from "../../Models/Account/Account";
import MyAppointmentsFuture from "../../Models/Appointments/MyAppointmentsFuture";
import GeneralPractitionerSchedule from "../../Models/Appointments/GeneralPractitionerSchedule";
import MyCalendarModel from "../../Models/Appointments/MyCalendarModel";
import Speciality from "../../Models/Speciality/Speciality";
import User from "../../Models/User/User";
import ProfesionalSchedule from "../../Models/Profesional/ProfesionalSchedule";
import { GetProfessionalsForAppointmentsResponse } from "../../Models/Appointments/GetProfessionalsForAppointments";
import HealthInsuranceCopayment from "../../Models/HealthInsurance/HealthInsuranceCopayment";
import RelatedPeople from "../../Models/Patient/RelatedPeople";
import ZoomDetails from "../../Models/Zoom/ZoomDetails";
import Service from "../../Models/Service/Service";
import Prescription from "../../Models/Prescriptions/Prescription";
import Medicine from "../../Models/Medicine/Medicine";

const Entity = GetEntities();

function fillHealthInsurance(genericObj) {
  return new HealthInsurance(genericObj.Id, genericObj.Name, genericObj.Plan, genericObj.ElectronicPrescriptionProvider);
}

function fillPatient(genericObj) {
  return new Patient(
    genericObj.Id,
    genericObj.FullName,
    genericObj.Name,
    genericObj.LastName,
    genericObj.DateOfBirth,
    genericObj.DocumentNumber,
    genericObj.PhoneNumber,
    genericObj.CellPhoneNumber,
    genericObj.Email,
    genericObj.UserName,
    genericObj.DocumentTypeId,
    genericObj.AddressStreet,
    genericObj.AddressNumber,
    genericObj.PostalCode,
    genericObj.AddressAdditionalDetails,
    genericObj.TownId,
    genericObj.ProvinceId,
    genericObj.CountryId,
    genericObj.GeneralPractitionersIds,
    genericObj.HealthInsuranceId,
    genericObj.HealthInsuranceNumber,
    genericObj.CareModalityId,
    genericObj.GenderId
  );
}

function fillRelatedPeople(genericObj) {
  return new RelatedPeople(
    genericObj.Id,
    genericObj.FullName,
    genericObj.Name,
    genericObj.LastName,
    genericObj.DateOfBirth,
    genericObj.DocumentNumber,
    genericObj.PhoneNumber,
    genericObj.CellPhoneNumber,
    genericObj.Email,
    genericObj.UserName,
    genericObj.DocumentTypeId,
    genericObj.AddressStreet,
    genericObj.AddressNumber,
    genericObj.PostalCode,
    genericObj.AddressAdditionalDetails,
    genericObj.TownId,
    genericObj.ProvinceId,
    genericObj.CountryId,
    genericObj.PatientId,
    genericObj.Order,
    genericObj.IsResponsable,
    genericObj.Relationship,
    genericObj.RelationshipId
  );
}

function fillProfessional(genericObj) {
  return new Profesional(
    genericObj.Id,
    genericObj.FullName,
    genericObj.Name,
    genericObj.LastName,
    genericObj.DateOfBirth,
    genericObj.DocumentNumber,
    genericObj.PhoneNumber,
    genericObj.CellPhoneNumber,
    genericObj.Email,
    genericObj.UserName,
    genericObj.DocumentTypeId,
    genericObj.AddressStreet,
    genericObj.AddressNumber,
    genericObj.PostalCode,
    genericObj.AddressAdditionalDetails,
    genericObj.TownId,
    genericObj.ProvinceId,
    genericObj.CountryId,
    genericObj.SpecialtyId,
    genericObj.SpecialtyName,
    genericObj.RegistrationNumber,
    genericObj.IsActive,
    genericObj.Remarks,
    genericObj.GoogleAccountEmail,
    genericObj.Cuit,
    genericObj.PreserfarMedicoId
  );
}

function fillMyAppointmentsFuture(genericObj) {
  return new MyAppointmentsFuture(
    genericObj.Id,
    genericObj.PatientName,
    genericObj.PatientId,
    genericObj.ProfessionalId,
    genericObj.ProfessionalName,
    genericObj.CareModalityId,
    genericObj.CareModalityName,
    genericObj.ServiceId,
    genericObj.ServiceName,
    genericObj.HealthInsuranceId,
    genericObj.HealthInsuranceName,
    genericObj.HealthInsuranceNumber,
    genericObj.AppointmentDateTime,
    genericObj.SiteId,
    genericObj.SiteName,
    genericObj.AssistanceId,
    genericObj.AssistanceName,
    genericObj.RoomId,
    genericObj.RoomName,
    genericObj.HC,
    genericObj.IsOnline,
    genericObj.ZoomEmail,
    genericObj.MeetingNumber,
    genericObj.AccessCode,
    genericObj.OnlineMeetingLink
  );
}

function fillGeneralPractitionerSchedule(genericObj) {
  return new GeneralPractitionerSchedule(
    genericObj.AppointmentDateTime,
    genericObj.IsAvailable
  );
}

function fillMyCalendar(genericObj) {
  return new MyCalendarModel(
    genericObj.Id,
    genericObj.PatientName,
    genericObj.PatientId,
    genericObj.ProfessionalId,
    genericObj.ProfessionalName,
    genericObj.CareModalityId,
    genericObj.CareModalityName,
    genericObj.ServiceId,
    genericObj.ServiceName,
    genericObj.HealthInsuranceId,
    genericObj.HealthInsuranceName,
    genericObj.HealthInsuranceNumber,
    genericObj.AppointmentDateTime,
    genericObj.SiteId,
    genericObj.SiteName,
    genericObj.AssistanceId,
    genericObj.AssistanceName,
    genericObj.RoomId,
    genericObj.RoomName,
    genericObj.HC,
    genericObj.IsOnline,
    genericObj.ZoomEmail,
    genericObj.MeetingNumber,
    genericObj.AccessCode,
    genericObj.OnlineMeetingLink
  );
}

function fillSpeciality(genericObj) {
  return new Speciality(genericObj.Id, genericObj.Name);
}

function fillUser(genericObj) {
  return new User(
    genericObj.Id,
    genericObj.FullName,
    genericObj.Name,
    genericObj.LastName,
    genericObj.DateOfBirth,
    genericObj.DocumentNumber,
    genericObj.PhoneNumber,
    genericObj.CellPhoneNumber,
    genericObj.Email,
    genericObj.UserName,
    genericObj.DocumentTypeId,
    genericObj.AddressStreet,
    genericObj.AddressNumber,
    genericObj.PostalCode,
    genericObj.AddressAdditionalDetails,
    genericObj.TownId,
    genericObj.ProvinceId,
    genericObj.CountryId,
    genericObj.Profile,
    genericObj.ProfileId,
    genericObj.Operations
  );
}

function fillProfessionalSchedule(genericObj) {
  return new ProfesionalSchedule(
    genericObj.Id,
    genericObj.ProfessionalId,
    genericObj.DayOfWeek,
    genericObj.From,
    genericObj.To,
    genericObj.RoomId,
    genericObj.RoomName,
    genericObj.ServiceIds,
    genericObj.CareModalityId
  );
}

function fillProfesionalForAppointments(genericObj) {
  return new GetProfessionalsForAppointmentsResponse(
    genericObj.Id,
    genericObj.Name,
    genericObj.LastName,
    genericObj.IsWorking
  );
}

function fillHealthInsuranceCopayment(genericObj) {
  return new HealthInsuranceCopayment(
    genericObj.Id,
    genericObj.DateFrom,
    genericObj.ServiceId,
    genericObj.ServiceName,
    genericObj.CareModalityId,
    genericObj.CareModalityName,
    genericObj.Cost,
    genericObj.HealthInsuranceId
  );
}

function fillAccountDetails(genericObj) {
  return new Account(
    genericObj.Id,
    genericObj.Name,
    genericObj.SettingLiveConnectAccessKey,
    genericObj.SettingLiveConnectChannelId,
    genericObj.SettingLiveConnectPrivateKey,
    genericObj.Url,
    genericObj.GoogleCloudServiceAccountCredential,
    genericObj.WebSiteUrl,
    genericObj.PhoneNumber,
    genericObj.Email,
    genericObj.FullAddressInformation,
    genericObj.LegalName,
    genericObj.BrandingPrimaryColor,
    genericObj.BrandingSecondaryColor
  );
}

function fillZoomDetails(genericObj) {
  return new ZoomDetails(
    genericObj.ClientId,
    genericObj.Client.Secret,
    genericObj.SdkKey,
    genericObj.SdkSecret
  );
}

function fillService(genericObj) {
  return new Service(
    genericObj.Id,
    genericObj.Name,
    genericObj.CareModalityId,
    genericObj.CareModalityName,
    genericObj.MaxSelfServiceAppointmentsPerWeek,
    genericObj.MaxSelfServiceAppointmentsPerMonth
  );
}

function fillPrescription(genericObj) {
  return new Prescription();
}

function fillMedicine(genericObj) {
  return new Medicine(
    genericObj.Id,
    genericObj.Code,
    genericObj.Name,
    genericObj.Laboratory,
    genericObj.Presentation,
    genericObj.Drug,
    genericObj.PresentationShape
  );
}

const FillEntity = (entityType, genericObj) => {
  switch (entityType) {
    case Entity.ObraSocial:
      return fillHealthInsurance(genericObj);
    case Entity.Paciente:
      return fillPatient(genericObj);
    case Entity.Profesional:
      return fillProfessional(genericObj);
    case Entity.MisTurnosFuturos:
      return fillMyAppointmentsFuture(genericObj);
    case Entity.HorarioGeneralProfesional:
      return fillGeneralPractitionerSchedule(genericObj);
    case Entity.MiAgenda:
      return fillMyCalendar(genericObj);
    case Entity.Especialidad:
      return fillSpeciality(genericObj);
    case Entity.Usuario:
      return fillUser(genericObj);
    case Entity.Agenda:
      return fillProfessionalSchedule(genericObj);
    case Entity.AgendaProfesional:
      return fillProfesionalForAppointments(genericObj);
    case Entity.Copago:
      return fillHealthInsuranceCopayment(genericObj);
    case Entity.Familiares:
      return fillRelatedPeople(genericObj);
    case Entity.Cuenta:
      return fillAccountDetails(genericObj);
    case Entity.CuentasZoom:
      return fillZoomDetails(genericObj);
    case Entity.Prestacion:
      return fillService(genericObj);
    case Entity.Recetas:
      return fillPrescription(genericObj);
    case Entity.Medicina:
      return fillMedicine(genericObj);
    default:
      console.log("Feature FillEntity no esta disponible para esta entidad");
      break;
  }
};

export default FillEntity;
